<template>
  <div>
    <incoming-invoice-home :incomingInvoices="incomingInvoices"/>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import incomingInvoiceHome from '@/components/home/incomingInvoice/IncomingInvoice'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    incomingInvoiceHome,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { incomingInvoices } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      incomingInvoices,

      // Methods

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>