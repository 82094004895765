import { ref, computed, watch } from '@vue/composition-api'
import { useIncomingInvoices } from './useIncomingInvoices'
import { capitalize, currency } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import useAPI from '@/utils/useAPI'

import ModalUploadIncomingInvoice from '@/components/prompt/Upload'
import ModalIncomingInvoice from '@/components/prompt/IncomingInvoice'

export const mixinIncomingInvoices = {
  components: {
    ModalUploadIncomingInvoice,
    ModalIncomingInvoice
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const incomingInvoiceModalShow = ref(false)
    const incomingInvoiceModalTitle = ref(i18n.t('NewIncomingInvoice'))
    const currentIncomingInvoice = ref({})
    const isIncomingInvoiceAcceptanceModalOpen = ref(false)
    const submitIncomingInvoiceFormRef = ref(null)

    const outgoingInvoiceByUploadModalShow = ref(false)
    const newOutgoingInvoiceFile = ref(null)
    const isCompanyLoading = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(incomingInvoiceModalShow, () => {
      if (incomingInvoiceModalShow.value == false) {
        resetIncomingInvoice()
      }
    })

    watch(currentIncomingInvoice, () => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        currentIncomingInvoice.value.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }

        // if (
        //   !('id' in currentIncomingInvoice.value) &&
        //   currentIncomingInvoice.value.customerCompany == null &&
        //   currentIncomingInvoice.value.customerIndividual == null
        // ) {
        //   currentIncomingInvoice.value.customerCompany = workflow.customerCompany
        //   currentIncomingInvoice.value.customerIndividual = workflow.customerIndividual
        //
        //   if (workflow.customerCompany != null && workflow.customerCompany.addresses.length) {
        //     currentIncomingInvoice.value.billingAddress = workflow.customerCompany.addresses[0]
        //     currentIncomingInvoice.value.deliveryAddress = workflow.customerCompany.addresses[0]
        //   }
        // }
      }

    })

    watch(newOutgoingInvoiceFile, (val) => {
      if (val != null) {
        recognizeIncomingInvoice(val)
          .then(response => {
            outgoingInvoiceByUploadModalShow.value = false
            newOutgoingInvoiceFile.value = null
            currentIncomingInvoice.value = response.data
            incomingInvoiceModalShow.value = true
          })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      companies,
      fetchArticles,
      fetchBankAccounts,
      fetchCompanies,
      fetchIncomingInvoices,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchUsers,
      fetchVats,
      fetchWorkflows
    } = useAPI()

    const {
      submitValidatedIncomingInvoice,
      removeIncomingInvoice,
      downloadIncomingInvoice,
      recognizeIncomingInvoice
    } = useIncomingInvoices()

    const populateWorkflow = (incomingInvoice) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        incomingInvoice.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }
      }
    }

    const updateWorkflow = (updatedIncomingInvoice) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        if ('id' in updatedIncomingInvoice) {
          let index = workflow.incomingInvoices.findIndex(o => o.id == updatedIncomingInvoice.id)
          workflow.incomingInvoices.splice(index, 1, JSON.parse(JSON.stringify(updatedIncomingInvoice)))
        } else if (updatedIncomingInvoice != null) {
          workflow.incomingInvoices.push(updatedIncomingInvoice)
        } else {
          workflow.incomingInvoices.splice(index, 1)
        }
      }
    }

    const addIncomingInvoice = () => {
      incomingInvoiceModalShow.value = true
    }

    const selectIncomingInvoice = (incomingInvoice) => {
      incomingInvoiceModalTitle.value = i18n.t('EditInvoice')

      fetchIncomingInvoices(incomingInvoice.id).then((incomingInvoice) => {
        currentIncomingInvoice.value = incomingInvoice
        populateWorkflow(currentIncomingInvoice.value)

        incomingInvoiceModalShow.value = true
      })

    }

    const tryCloseIncomingInvoiceModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitIncomingInvoiceFormRef.value.click()
      }
    }

    const submitValidatedIncomingInvoiceLocal = () => {
      submitValidatedIncomingInvoice(currentIncomingInvoice.value)
        .then(response => {
          incomingInvoiceModalShow.value = false

          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            // delete response.data.workflow
            response.data.workflow = response.data.workflow.id

            if ('id' in currentIncomingInvoice.value) {
              let index = workflow.incomingInvoices.findIndex(i => i.id == currentIncomingInvoice.value.id)
              workflow.incomingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            } else {
              workflow.incomingInvoices.push(response.data)
            }

          }
        })
    }

    const resetIncomingInvoice = () => {
      currentIncomingInvoice.value = JSON.parse(JSON.stringify(store.getters['incomingInvoice/getEmptyIncomingInvoice']))
    }

    const removeIncomingInvoiceLocal = (incomingInvoice) => {
      removeIncomingInvoice(incomingInvoice)
        .then(response => {
          if (getRoute().name == 'IncomingInvoice view') {
            router.push({ name: 'IncomingInvoices' })
          } else if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            workflow.incomingInvoices = workflow.incomingInvoices.filter(ii => ii.id != incomingInvoice.id)
          }
        })
    }

    const addIncomingInvoiceByUpload = () => {
      outgoingInvoiceByUploadModalShow.value = true
    }

    const archivateIncomingInvoiceLocal = (incomingInvoice, isArchived) => {
      currentIncomingInvoice.value = JSON.parse(JSON.stringify(incomingInvoice))
      // populateWorkflow(currentIncomingInvoice.value)
      currentIncomingInvoice.value.isArchived = isArchived

      submitValidatedIncomingInvoice(currentIncomingInvoice.value)
        .then(response => {
          updateWorkflow(response.data)

          resetIncomingInvoice()
        })
    }

    const duplicateIncomingInvoice = (incomingInvoice) => {
      fetchIncomingInvoices(incomingInvoice.id).then((incomingInvoice) => {
        currentIncomingInvoice.value = incomingInvoice
        populateWorkflow(currentIncomingInvoice.value)
        delete currentIncomingInvoice.value.id
        delete currentIncomingInvoice.value.logs
        delete currentIncomingInvoice.value.orders
        delete currentIncomingInvoice.value.receipt

        incomingInvoiceModalShow.value = true
      })
    }

    const getPreTaxAmounts = (incomingInvoices) => {
      let preTaxAmount = 0
      incomingInvoices.forEach(ii => preTaxAmount += parseFloat(ii.preTaxAmount))

      return preTaxAmount
    }

    const getTotalAmounts = (incomingInvoices) => {
      let totalAmount = 0
      incomingInvoices.forEach(ii => totalAmount += parseFloat(ii.totalAmount))

      return totalAmount
    }

    const fetchForDetails = () => {
      fetchArticles()
      fetchBankAccounts()
      // fetchCompanies()
      fetchPaymentMethods()
      fetchPaymentTerms()
      // fetchUsers()
      fetchVats()
      fetchWorkflows()
      fetchBankAccounts()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name.startsWith('Incoming')) {
      if (getRoute().params != null && ('incomingInvoiceId' in getRoute().params)) {
        fetchIncomingInvoices(getRoute().params.incomingInvoiceId).then(() => {
          fetchUsers().then(() => {
            fetchCompanies().then(() => {
              isCompanyLoading.value = false
              fetchForDetails()
            })
          })
        })
      } else {
        fetchIncomingInvoices().then(() => {
          fetchUsers().then(() => {
            fetchCompanies().then(() => {
              isCompanyLoading.value = false
              fetchForDetails()
            })
          })
        })
      }
    } else {
      if (companies.value.length) {
        isCompanyLoading.value = false
      } else {
        fetchCompanies().then(() => {
          isCompanyLoading.value = false
        })
      }
    }

    resetIncomingInvoice()

    return {
      // Components
      capitalize,
      currency,

      // Data
      incomingInvoiceModalShow,
      incomingInvoiceModalTitle,
      currentIncomingInvoice,
      isIncomingInvoiceAcceptanceModalOpen,
      submitIncomingInvoiceFormRef,
      outgoingInvoiceByUploadModalShow,
      newOutgoingInvoiceFile,
      isCompanyLoading,

      // Computed

      // Methods
      addIncomingInvoice,
      selectIncomingInvoice,
      tryCloseIncomingInvoiceModal,
      submitValidatedIncomingInvoiceLocal,
      downloadIncomingInvoice,
      recognizeIncomingInvoice,
      removeIncomingInvoice,
      removeIncomingInvoiceLocal,
      addIncomingInvoiceByUpload,
      archivateIncomingInvoiceLocal,
      duplicateIncomingInvoice,
      getPreTaxAmounts,
      getTotalAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // newOutgoingInvoiceFile (val) {
    //   this.recognizeIncomingInvoice(val)
    //     .then(result => {
    //       if (result.data != '') {
    //         this.currentIncomingInvoice = result.data
    //       }
    //
    //       this.outgoingInvoiceByUploadModalShow = false
    //
    //       this.incomingInvoiceModalShow = true
    //     })
    // }
  },
  methods: {
    deleteIncomingInvoiceAlert (incomingInvoice) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theIncomingInvoice') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeIncomingInvoiceLocal(incomingInvoice)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}